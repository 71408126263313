import Choices from "choices.js";
import RequestManager from "../../admin/utils/requests";

if ( document.getElementById('albums-index') ) {

    const choices_items = [];
    document.querySelectorAll('.choices-select').forEach(e => {
        const item = new Choices(e, {
            shouldSort: false
        });
        choices_items.push(item);
    });

    const choices_searchable = [];
    document.querySelectorAll('.choices-searchable').forEach(e => {
        const searchable = new Choices(e, {
            searchResultLimit: 10
        });
        const requestManager = new RequestManager();

        searchable.passedElement.element.addEventListener('search', async (i) => {
            searchable.clearChoices();

            const url = e.dataset.endpoint + "?s=" + i.detail.value;
            const response = await requestManager.newRequest(url);

            if (response) {
                const options = [];
                response.results.forEach(u => {
                    options.push({value: u.id, label: u.name});
                });

                searchable.setChoices(options);
            }
        });
        choices_searchable.push(searchable);
    });

    const filterReset = document.getElementById('reset-album-filter');
    if (filterReset) {
        filterReset.addEventListener('click', () => {
            choices_items.forEach(i => {
                i.removeActiveItems();
            });
            choices_searchable.forEach(i => {
                i.removeActiveItems();
            });
        });
    }
}