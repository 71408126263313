const flagged = {
   "username_target": false,
   "email_target": false,
};

document.body.addEventListener('htmx:afterSwap', evt => {
   if ( ["username_target","email_target"].includes(evt.target.id)) {
      flagged[evt.target.id] = evt.target.innerHTML === "";
   }
});

const form = document.getElementById('user_signup');
if (form) {
   form.addEventListener("submit", e => {
      if (!Object.values(flagged).includes(true)) {
         e.preventDefault();
      }
   });
}