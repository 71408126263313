class Requests {
    controller = new AbortController();

    constructor( url ) {
        this.url = url;
    }

    async start() {
        try {
            const response = await fetch(this.url, {
                signal: this.controller.signal
            });

            return await response.json();
        } catch (e) {
            return null;
        }
    }

    async results() {
        return await this.response.json();
    }

    abort() {
        this.controller.abort('Cancelled previous request.');
    }
}

class Queue {
    queue = [];

    read() {
        return this.queue.shift();
    }

    insert( value ) {
        this.queue.push(value);
    }

}
export default class RequestManager {
    queue = new Queue();

    async newRequest(url) {
        const current = this.queue.read();
        if ( typeof current !== 'undefined' ) {
            current.abort();
        }

        const request = new Requests(url);
        this.queue.insert(request);

        return await request.start();
    }
}