import "velocity-animate";
import "velocity-animate/velocity.ui";
window.htmx = require("htmx.org");

let searchCloseAllowed = false;
let search = document.getElementById("search-results");

window.htmx.on("htmx:afterSwap", e => {
    if ( e.target.id === "search-results") {
        searchCloseAllowed = !e.target.classList.contains('hidden');
        search = e.target;

        searchCloseAllowed = true;
        e.target.addEventListener("mouseenter", () => {
            searchCloseAllowed = false;
        });

        e.target.addEventListener("mouseleave", () => {
            searchCloseAllowed = true;
        });
    }
});

document.addEventListener("click", () => {
    if ( searchCloseAllowed ) {
        document.getElementById('header-search').value = '';

        search.innerHTML = "";
        search.className = "hidden";
    }
});

const mobileBtn = document.querySelector('.mobile-btn');
const nav = document.getElementById('mobile-navigation');
if ( mobileBtn ) {
    mobileBtn.addEventListener('click', e => {
        e.preventDefault();
        e.currentTarget.classList.toggle('open');

        const direction = e.currentTarget.classList.contains('open') ? "slideInDown" : "slideOutUp";
        nav.velocity(direction, {
            duration: 100,
            complete: () => {
                if( direction === 'slideOutUp' ) {
                    nav.removeAttribute('style');
                }
            }
        });
    });
}