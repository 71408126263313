import Swiper from 'swiper';
import { Navigation } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';

const mainSwiper = new Swiper('.main-swiper', {
  modules: [Navigation],
  loop: true,
  navigation: {
    nextEl: ".rnb-banner-next",
    prevEl: ".rnb-banner-prev",
  }
});

const navigationSwiper = new Swiper('.swiper-navigation', {
  loop: true,
  direction: 'vertical',
  slidesPerView: 3,
  spaceBetween: 10,
  initialSlide: 1
});

mainSwiper.on('realIndexChange', (e) => {
  if ( e.translate > e.previousTranslate ) {
    navigationSwiper.slidePrev();
  } else {
    navigationSwiper.slideNext();
  }
});



