import "../styles/index.css";
import "choices.js/public/assets/styles/choices.min.css";
import "htmx.org";
import "@fontsource-variable/source-sans-3";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Cookie from "js-cookie";
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';

import "toastr/build/toastr.min.css";
import toastr from "toastr";

toastr.options = {
    closeButton: true,
    preventDuplicates: true,
    progressBar: true
};
window.toastr = toastr;

import "./frontend/template/header";
import "./frontend/views/albums";
import "./frontend/views/artists";
import "./frontend/views/signup";
import "./frontend/views/user_account";
import "./frontend/views/home";

// Initial Tippy
tippy('[data-tippy-content]');

// Handle and delete requests from HTMX
document.body.addEventListener('htmx:configRequest', evt => {
    if ( evt.detail.verb === 'delete' || evt.detail.verb === 'post' ) {
        evt.detail.headers['X-CsrfToken'] = Cookie.get('csrftoken');
    }
});

document.addEventListener('htmx:afterRequest', evt => {
    if ( typeof evt.target.dataset.toastr !== 'undefined' ) {
        window.toastr.success(evt.target.dataset.message, "Success");
    } else if ( typeof evt.detail.etc.toastr !== 'undefined' ) {
        window.toastr.success(evt.detail.etc.message, 'Success');
    }
});

document.addEventListener('htmx:beforeRequest', evt => {
    if ( typeof evt.target.dataset.toastr !== 'undefined' ) {
        evt.detail.etc['toastr'] = true;
        evt.detail.etc['message'] = evt.target.dataset.message;
    }
});
