import Choices from "choices.js";

if ( document.getElementById('artist-single')) {
    const bio = document.getElementById('artist-bio');
    if (bio) {
        if (bio.clientHeight > 150) {
            bio.style.textOverflow = "ellipsis";
            bio.style.height = "150px";
            bio.style.overflow = "hidden";

            const elem = document.createElement('div');
            const elemSpan = document.createElement('span');

            elem.style.textAlign = "right";
            elemSpan.id = "toggle-show";
            elemSpan.style.cursor = "pointer";
            elemSpan.style.display = "inline-block";
            elemSpan.innerText = "Show more";
            elem.append(elemSpan);

            bio.after(elem);

            elemSpan.addEventListener('click', () => {
                elemSpan.innerText = bio.style.height === "150px" ? "Show Less" : "Show More";
                bio.style.height = bio.style.height === "150px" ? "auto" : "150px";
            });
        }
    }

    const artistTabs = document.querySelectorAll('.rnb-accordion button');
    artistTabs.forEach(e => {
        e.addEventListener('click', i => {
            i.target.children[1].classList.toggle('fa-angle-down');
            i.target.children[1].classList.toggle('fa-angle-up');
            i.target.nextElementSibling.classList.toggle('hidden');
        });
    });
}

if ( document.getElementById('artists-index') ) {
    const mobileArtistSelect = document.getElementById('artist-mobile-sort');
    new Choices(mobileArtistSelect);

    mobileArtistSelect.addEventListener('change', (e) => {
        let endpoint = e.target.dataset.endpoint;
        if ( e.target.value !== "" ) {
            endpoint += `?sort=${e.target.value}`;
        }
        window.location.href = endpoint;
    });
}