import Uppy from "@uppy/core";
import Cookie from "js-cookie";
import DragDrop from "@uppy/drag-drop";
import XHRUpload from "@uppy/xhr-upload";

const user_tabs = document.querySelectorAll('.user-tab');
const user_content = document.querySelectorAll('.user-content');
user_tabs.forEach( e => {
   e.addEventListener('click', i => {
      const content = i.target.dataset.content;
      user_content.forEach( u => {
         u.classList.add('hidden');
      });
      user_tabs.forEach( u => {
         u.classList.remove('active');
      });

      i.target.classList.add('active');
      document.getElementById(content).classList.remove('hidden');
   });
});

const uploader = document.getElementById('user-media');
if ( uploader ) {
   const token = Cookie.get('csrftoken');
   const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
         maxNumberOfFiles: 1
      }
   }).use(DragDrop, {
      target: '#user-media',
   }).use(XHRUpload, {
      endpoint: "/account/images/",
      method: "post",
      formData: true,
      bundle: true,
      validateStatus: (statusCode, responseText) => {
         const text = JSON.parse(responseText);
         return text.result !== 'failed';
      },
      headers: {
         'X-CSRFToken' : token
      },
      mode: "same-origin"
   });

   uppy.on('upload-success', (file, response) => {
      const img = new Image();
      const thumbnailContainer = document.getElementById('thumbnail-container');
      const input = document.getElementById('thumbnail');

      img.src = response.body.url;
      img.style.position = "relative";
      img.style.objectFit = "cover";
      img.style.width = "100%";
      img.style.height = "100%";

      thumbnailContainer.innerHTML = '';
      thumbnailContainer.appendChild(img);
      input.value = response.body.id;
   });

   uppy.on('upload-error', (file, response) => {
      console.error(response);
      alert('Sorry there was an error uploading your file.');
   });
}
